import {
  CheckOutlined,
  FileProtectOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Spin,
  Upload,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import copy from "copy-to-clipboard";
import { navigate } from "gatsby-link";
import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
// import QrCode from "qrcode.react";
import { QRCode } from "react-qrcode-logo";
import { connect } from "react-redux";
import { feathers } from "../../../api/feathers_rest";
import { usdtIcon } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import createDepositRequest from "../../../newApi/asset/deposit/createDepositRequest";
import upload from "../../../newApi/image/upload";
import { triggerModal } from "../../../redux/actions/app-actions";
import { routes } from "../../../route";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import Required2FAWrapper from "../components/Required2FAWrapper";
import RequiredPaymentPasswordWrapper from "../components/RequiredPaymentPasswordWrapper";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

// function beforeUpload(file) {
//   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
//   if (!isJpgOrPng) {
//     message.error("You can only upload JPG/PNG file!");
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2;
//   if (!isLt2M) {
//     message.error("Image must smaller than 2MB!");
//   }
//   return false;
// }

// markup
const DepositPage = (props) => {
  const accessKey = get(props.user, "accessKey");
  // const location = useLocation();
  const { t } = useTranslation();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [selected, setSelected] = useState(1);
  const [ercData, setErcData] = useState("");
  const [trcData, setTrcData] = useState("");
  const [qrCodeValue, setQrCodeValue] = useState("");
  const [binanceAddress, setBinanceAddress] = useState("");
  const [depositDesc, setDepositDesc] = useState("");
  const [file, setFile] = useState();
  const [confirmVisible, setConfirmVisible] = useState(false);
  const user = get(props, "user.user");

  function getQrCode() {
    feathers("system-configuration", accessKey)
      .find({ key: "depositQrCode" })
      .then((res) => {
        let result = get(res, "result[0]");
        if (!isEmpty(get(result, "depositQrCode"))) {
          setQrCodeValue(get(result, "depositQrCode"));
        }
      })
      .catch((err) => {});
  }

  function getAddress() {
    feathers("system-configuration", accessKey)
      .find({ key: "binanceWalletLinkAddress" })
      .then((res) => {
        let result = get(res, "result[0]");
        if (!isEmpty(get(result, "binanceWalletLinkAddress"))) {
          setBinanceAddress(get(result, "binanceWalletLinkAddress"));
        }
      })
      .catch((err) => {});
  }

  function getDesc() {
    feathers("system-configuration", accessKey)
      .find({ key: "depositDescription" })
      .then((res) => {
        let result = get(res, "result[0]");
        setDepositDesc(get(result, "depositDescription"));
      })
      .catch((err) => {});
  }

  useEffect(() => {
    getQrCode();
    getAddress();
    getDesc();
  }, []);

  function handleSubmit() {
    let correctValues = {};
    form
      .validateFields()
      .then((values) => {
        if (get(file, "[0].originFileObj")) {
          setLoading(true);
          upload({ image: get(file, "[0].originFileObj") }, accessKey)
            .then((res) => {
              // console.log(res);
              let res_data = get(res, "data");
              let image_url = get(res_data, "url");
              let image_key = get(res_data, "key");

              correctValues = {
                chargeAddress: binanceAddress,
                transactionId: values.txid,
                amount: parseFloat(values.amount),
                images: [{ key: image_key, url: image_url }],
                profileId: get(user, "profile._id"),
                createdBy: get(user, "_id"),
              };
              createDepositRequest(correctValues)
                // feathers("deposit-transactions", accessKey)
                // .create(correctValues)
                .then((res) => {
                  message.success(t("submitted", sourceKey.asset));
                  setConfirmVisible(true);
                  setLoading(false);
                })
                .catch((err)=>{
                  console.log(err);
                  setLoading(false);
                  message.error(err?.message)
                })
            })
            .catch((err) => {
              setLoading(false);
              // console.log("up", err);
            });
        }
      })
      .catch((err) => {
        message.error(t("keyInAllInfo", sourceKey.highFrequency));
        setLoading(false);
        //console.log("error", err);
      });
  }

  const handleChange = (info) => {
    //console.log("v?", info);
    setFile(info.fileList);
    //console.log("file list", info.fileList);
    getBase64(info.file, (imageUrl) => {
      //console.log('url',imageUrl);
      setImageUrl(imageUrl);
    });
  };

  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <PlusOutlined style={{ color: "black" }} />
      )}
      <div style={{ marginTop: 8, color: "black" }}>
        {t("upload", sourceKey.asset)}
      </div>
    </div>
  );

  const _renderDetails = (title) => {
    const erc = title === "ETH";
    return (
      <div
        className="rounded-3xl greybox-bg-color box-shadow-center mt-4 mx-2 pb-2"
        style={{ color: "white" }}
      >
        <div className="pt-2">
          <span className="pl-5">
            {/* 地址  */}
            {t("address", sourceKey.asset)}
          </span>
          <span className="flex justify-center mt-5">
            <QRCode
              logoImage={usdtIcon}
              logoHeight={50}
              logoWidth={50}
              value={qrCodeValue}
              size={150}
            ></QRCode>
          </span>
          <div className="inline-block text-sm font-semibold pl-5 pt-5">
            {title}
          </div>
          <div className=" flex justify-between">
            <div className=" pl-5">
              {" "}
              {erc ? "Ethereum(ERC20)" : "Tron(TRC20)"}
            </div>

            <div>
              <div
                className=" cursor-pointer pr-4"
                style={{
                  color: "#FFE500",
                }}
                onClick={(e) => {
                  message.success(t("copied", sourceKey.asset));
                  //copy(erc ? ercData : trcData);
                  copy(binanceAddress);
                }}
              >
                {/* 复制地址  */}
                {t("copyAddress", sourceKey.asset)}
              </div>
            </div>
          </div>
          <div className="text-gray-400 underline pl-5 text-xs">
            {erc ? ercData : trcData}
          </div>
        </div>
      </div>
    );
  };

  let desc_array = [];
  if (!isEmpty(depositDesc)) {
    desc_array = depositDesc.split("\n");
  }

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            suffix={
              <React.Fragment>
                <div className="justify-end flex items-center">
                  {/* 充币 <MediaQuery maxWidth={440}><br/></MediaQuery> */}
                  {t("deposit", sourceKey.asset)}
                </div>
              </React.Fragment>
            }
            after={
              <FileProtectOutlined
                className="black  mx-2"
                style={{ fontSize: 23 }}
                onClick={() => navigate(routes.depositList.to())}
              />
            }
            showBack={true}
          >
            <Required2FAWrapper />
            <RequiredPaymentPasswordWrapper />
            <div className="px-6 py-5">
              <div className="flex justify-around">
                {/* <div className='border px-12 py-2 rounded-xl' style={{ borderColor: selected === 0 ? 'gold' : 'lightgray', color: selected === 0 ? 'gold' : 'gray' }} onClick={() => { setSelected(0) }}>
                                    ERC20
                                </div> */}
                <div
                  className="border px-12 py-2 rounded-xl"
                  style={{
                    borderColor: selected === 1 ? "gold" : "lightgray",
                    color: selected === 1 ? "gold" : "gray",
                  }}
                  onClick={() => {
                    setSelected(1);
                  }}
                >
                  TRC20
                </div>
              </div>
              <Form form={form} style={{ color: "black" }}>
                {selected == 0 ? _renderDetails("ETH") : _renderDetails("TRX")}
                <div className="pl-2 mt-3">
                  <span className="inline-block text-l font-semibold">
                    {/* 请填写以下信息,提交充币审核
                    <br /> */}
                    {t("fillInApprove", sourceKey.asset)}
                  </span>
                </div>
                <div className="flex items-center my-3 pl-2 mt-3">
                  <span className="inline-block w-1/3 mr-3">
                    <div className="text-sm">
                      {/* 区块链交易ID <br></br> */}
                      {t("transactionId", sourceKey.asset)}
                    </div>
                  </span>
                  <span className="inline-block w-2/3">
                    <Form.Item
                      style={{ margin: 0 }}
                      name="txid"
                      rules={[
                        {
                          required: true,
                          message: (
                            <React.Fragment>
                              {/* 请填写正确的区块链交易ID <br /> */}
                              {t("transactionId", sourceKey.asset)}
                              {/* Please insert ID */}
                            </React.Fragment>
                          ),
                        },
                      ]}
                    >
                      <Input
                        placeholder=""
                        className="w-full input-border"
                        style={{ width: "100%", height: "30px" }}
                      ></Input>
                    </Form.Item>
                  </span>
                </div>
                <div className="flex items-center my-3 pl-2 mt-3">
                  <span className="inline-block w-1/3 mr-3">
                    <div className="text-sm">
                      UID <br></br>
                    </div>
                  </span>
                  <span className="inline-block w-2/3">
                    <Form.Item
                      style={{ margin: 0 }}
                      initialValue={get(user, "referralCode")}
                      name="uid"
                      rules={[
                        {
                          required: true,
                          message: t("insertUid", sourceKey.asset),
                        },
                      ]}
                    >
                      <Input
                        placeholder=""
                        className="w-full input-border"
                        style={{ width: "100%", height: "30px" }}
                        disabled
                      ></Input>
                    </Form.Item>
                  </span>
                </div>
                <div className="flex items-center my-3 pl-2 mt-3">
                  <span className="inline-block w-1/3 mr-3">
                    <div className="text-sm">
                      {/* 充币数量 <br></br> */}
                      {t("depositAmount", sourceKey.asset)}
                    </div>
                  </span>
                  <span className="inline-block w-2/3">
                    <Form.Item
                      style={{ margin: 0 }}
                      name="amount"
                      rules={[
                        {
                          validator: (rule, value, callback) => {
                            if (!value) {
                              callback(
                                <React.Fragment>
                                  {/* 请填写充币数额 <br /> */}
                                  {t("inputAmount", sourceKey.asset)}
                                </React.Fragment>
                              );
                            } else if (isNaN(parseFloat(value))) {
                              callback(
                                <React.Fragment>
                                  {/* 请填写数字 <br /> */}
                                  {t("inputNumber", sourceKey.asset)}
                                </React.Fragment>
                              );
                            } else if (value < 10) {
                              callback(
                                <React.Fragment>
                                  {/* 最低额度为10 <br /> */}
                                  {t("minTen", sourceKey.asset)}
                                </React.Fragment>
                              );
                            } else {
                              callback();
                            }
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder={t("inputAmount", sourceKey.asset)}
                        className="w-full input-border"
                        style={{ width: "100%", height: "30px" }}
                        precision={0}
                        formatter={(value) =>
                          isNaN(parseInt(value)) ? 0 : parseInt(value)
                        }
                      ></InputNumber>
                    </Form.Item>
                  </span>
                </div>
                <Form.Item
                  style={{ margin: 0 }}
                  name="receipt"
                  rules={[
                    {
                      required: true,
                      message: (
                        <React.Fragment>
                          {/* 请上传正确的收据 <br /> */}
                          {t("uploadReceipt", sourceKey.asset)}
                        </React.Fragment>
                      ),
                    },
                  ]}
                >
                  <div className="grid justify-items-center mt-5">
                    <div className="col-span-12">
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        accept="image/*"
                        fileList={file}
                        beforeUpload={(file) => false}
                        onChange={handleChange}
                      >
                        {imageUrl ? (
                          <img
                            src={imageUrl}
                            alt="avatar"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </div>
                  </div>
                </Form.Item>
                <div className="flex justify-center items-center mt-5">
                  <Button
                    className="main-button-color button-padding"
                    shape="round"
                    onClick={(e) => {
                      handleSubmit();
                    }}
                  >
                    <CheckOutlined style={{ fontSize: 20 }} />
                  </Button>
                </div>
                <div className="pl-2 mt-7">
                  <span className="inline-block text-l font-semibold">
                    {/* 操作提醒  */}
                    {t("reminder", sourceKey.asset)}:
                  </span>
                </div>
                <div className="pl-2 mt-7">
                  <span className="inline-block text-sm font-normal">
                    {desc_array.map((i) => {
                      return (
                        <>
                          {i}
                          <br />
                        </>
                      );
                    })}
                    {/* <br />
                    1.
                    该地区仅接受USDT,请勿往该地址转入非USDT资产包括其相关联资产，否则您的资产将无法找回。
                    <br />
                    Only USDT is accepted with this address,please do not
                    transfer any non-USDT assets into this address; otherwise
                    your assets will not be able to retrieved.
                    <br />
                    <br />
                    2. 最小充值金额 ：1.00 USDT,
                    小于该金额的充值将无法上账且无法退回。
                    <br />
                    The Min, deposit amount is 1.00 USDT; transfers less than
                    this amount will be invalid and cannot be retrieved.
                    <br />
                    <br />
                    3. 您充值至上达地址后，需要整个网络节点的确认，
                    2次网络确认后到账， 2次后方可提币。
                    <br />
                    Deposit needs to be confirmed by the entire blocks; the
                    deposit will be credited after confirmed 2 blocks;
                    withdrawal will be available after confirmed over 2 blocks. */}
                  </span>
                </div>
              </Form>
            </div>
          </NavHeader>
        </Spin>
      </Layout>
      <Modal
        wrapClassName="no-padding-modal-body modal-body-background-transparent"
        centered
        maskClosable
        mask
        footer={null}
        className=""
        visible={confirmVisible}
        closable={false}
        // onCancel={() => {
        //   setConfirmVisible(false)
        //   navigate(routes.asset.to())
        // }}
      >
        <div className="bg-white rounded-3xl px-5 py-10">
          <div className="items-center my-3">
            <div className="flex justify-center items-center mt-5">
              <Button
                className="success-button-color"
                shape="circle"
                disabled
                size={"large"}
              >
                <CheckOutlined style={{ fontSize: 20, color: "white" }} />
              </Button>
            </div>
            <div className="items-center my-3 flex justify-center">
              <span className="font-semibold">
                {t("submitSuccess", sourceKey.asset)}
              </span>
            </div>
            <div className="items-stretch text-center">
              <span>
                {/* 客服将在24小时内进行审核，您可前往“审核记录”查看审核结果
                <br /> */}
                {t("submitDesc1", sourceKey.asset)}
              </span>
            </div>
          </div>
          <div className="mt-5 mx-30 text-blue-400 items-center flex justify-center">
            <Button
              block
              className="go-button-color px-20 "
              size="large"
              onClick={(e) => {
                navigate(routes.depositList.to());
              }}
              style={{ color: "skyblue" }}
            >
              {/* 立即前往 */}
              {t("proceed", sourceKey.asset)}
            </Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(DepositPage);

import * as React from "react"
import SharedHeader from "../components/general/components/SharedHeader"
import DepositPage from "../components/robot/pages/DepositPage"


// markup
const Deposit = () => {
    return (
        <React.Fragment>
            <SharedHeader />
            <DepositPage />
        </React.Fragment>
    )
}

export default Deposit
